import * as React from 'react'
import {ParallaxBanner} from 'react-scroll-parallax';

const ParallaxContent = ({parentId}) => {

    const karteLayer = {
        expanded: false,
        translateY: [-7, -30],
        shouldAlwaysCompleteAnimation: true,
        children: (
            <div style={{
                "height": "110vh",
                "width": "100%",
                "text-align": "center"
            }}>
                <img src={'/images/KarteTitel.svg'} alt={''}
                     style={{
                         "height": "100%",
                         "width": "auto",
                     }}/>
            </div>
        )
    };

    const rautenLayer = {
        expanded: false,
        translateY: [0, 90],
        shouldAlwaysCompleteAnimation: true,
        children: (
            <div style={{
                "width": "100%",
                "height": "100vh",
                "text-align": "right"
            }}>
                <img src={'/images/Rauten-Titel.svg'} alt={''}
                     style={{
                         "height": "100%",
                         "width": "auto",
                         "margin": "auto",
                     }}/>
            </div>
        )
    }

    const zahlenLayer1 = {
        translateY: [2, 30],
        translateX: [1, 1],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div style={{
                'height': '80vh',
                'width': '100%',
                'position': 'relative'
            }}>
                <span className={"jahr px-1978"}>1978</span>
                <span className={"jahr px-1952"}>1952</span>
                <span className={"jahr px-1945"}>1945</span>
            </div>
        ),
    };

    const zahlenLayer2 = {
        translateY: [-15, 100],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div style={{
                'height': '80vh',
                'width': '100%',
                'position': 'relative'
            }}>
                <span className={"jahr px-1818"}>1818</span>
                <span className={"jahr px-1919"}>1919</span>
                <span className={"jahr px-1946"}>1946</span>
                <span className={"jahr px-1948"}>1948</span>
            </div>
        ),
    };

    const zahlenLayer3 = {
        translateY: [9, -100],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div style={{
                'height': '80vh',
                'width': '100%',
                'position': 'relative'
            }}>
                <span className={"jahr px-1663"}>1663</span>
                <span className={"jahr px-1949"}>1949</span>
                <span className={"jahr px-1832"}>1832</span>
                <span className={"jahr px-heute"}>heute</span>
                <span className={"jahr px-1525"}>1525</span>
            </div>
        ),
    };

    const meschenLayer = {
        translateX: [-40, 45],
        translateY: [-7, 70],
        shouldAlwaysCompleteAnimation: true,
        scale: [.3, 1.3, 'easeOutQuad'],
        expanded: false,
        children: (
            <div style={{height: '80vh'}}>
                <div className={"resident"}>
                    <img alt="Grafik mit Demonstranten für die Demokratie" src={'images/OdD-header.png'}/>
                </div>
            </div>
        ),
    };

    return (
        <ParallaxBanner
            layers={[karteLayer, rautenLayer, zahlenLayer1, zahlenLayer2, zahlenLayer3, meschenLayer]}
            style={{height: '80vh', overflow: 'visible'}}
        />
    )
}

export default ParallaxContent
