import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout";
import * as Container from "../components/container/container";
import ParallaxContent from "../components/parallax";
import OrteSlider from "../components/orteSlider";
import {ParallaxProvider} from 'react-scroll-parallax';

const IndexPage = (props) => {

    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main" className="startseite">
                <div className="headerpart">
                    <Container.Part background="white headline" env="w" style={{padding: '10px 0 0'}}>
                        <Container.Row>
                            <div className="col-12">
                                <h1>Die Geschichte der Demokratie in Bayern <br/> entdecken und erleben </h1>
                            </div>
                        </Container.Row>
                    </Container.Part>

                    <ParallaxProvider>
                        <Container.Part background="white headline" env="t">
                            <Container.Row>
                                <div className="col-12">
                                    <h1> Die Geschichte der Demokratie in Bayern entdecken und erleben </h1>
                                </div>
                            </Container.Row>
                        </Container.Part>

                        <Container.Part env="w" id="parallax-container">
                            <ParallaxContent parentId="parallax-container"/>
                        </Container.Part>

                        {/*<Container.Part env="l" id="parallax-container">*/}
                        {/*    <ParallaxContent parentId="parallax-container"/>*/}
                        {/*</Container.Part>*/}
                    </ParallaxProvider>

                </div>

                <Container.Part background="white" style={{padding: '0 0 50px'}}>
                    <Container.Row>
                        <div className="col-12">
                            <h2>Orte der Demokratie in Bayern</h2>
                            <p>
                                Ob Bamberg, Regensburg, München, Nürnberg oder Herrenchiemsee – zahlreiche Orte in
                                Bayern
                                sind Schauplätze demokratiegeschichtlicher Ereignisse. Dort haben sich Menschen für
                                das
                                demokratische Gemeinwesen eingesetzt. Der Wunsch nach Freiheit und Mitbestimmung
                                reicht
                                weit zurück in die Geschichte. Der Weg zur parlamentarischen Demokratie führte auch
                                in
                                Bayern über viele Stationen und war keine geradlinige Entwicklung. Die
                                Auseinandersetzung
                                mit der Demokratiegeschichte schärft das Bewusstsein für den Wert unserer heutigen
                                Demokratie.
                            </p>
                            <p>
                                Die Ausstellung lädt ein zur gedanklichen Reise an die historischen Schauplätze,
                                fragt
                                aber auch nach der heutigen Bedeutung der Orte.
                            </p>
                            <p>
                                Anhand von historischen Exponaten werden zentrale Momente, Personen und Ereignisse
                                der
                                Demokratiegeschichte Bayerns greifbar. Mit dem Element der Graphic Novel werden,
                                gestützt
                                auf Zeitzeugenberichte, Erinnerungen oder Dokumente, auch persönliche
                                Demokratiegeschichten
                                einzelner Bürgerinnen und Bürger dargestellt.
                            </p>
                            <ul>
                                <li><Link to="/wissenschaftlicher-beirat" className="icon-arrow_right icon-bg">Wissenschaftlicher
                                                                                                               Beirat</Link>
                                </li>
                                <li><Link to="/gedenkobjekt"
                                          className="icon-arrow_right icon-bg">Gedenkobjekt</Link>
                                </li>
                                <Container.If>
                                    <li><Link to="/ausstellung" className="icon-arrow_right icon-bg">
                                        Über die Ausstellung</Link>
                                    </li>
                                </Container.If>
                            </ul>
                        </div>
                    </Container.Row>

                    <Container.Row>
                        <div className="col-12">

                            <hr/>
                            <br/><br/>

                            <div className="inner-container">
                                <blockquote>
                                    „Die deutsche Geschichte des 20. Jahrhunderts hat gezeigt, wie schnell
                                    Demokratie
                                    verspielt werden kann. Deshalb müssen wir wachsam sein. Demokratie braucht Mut
                                    und
                                    Tatkraft. Demokratie braucht Bekenntnis und Einsatz. Dafür, dass wir in
                                    Demokratie,
                                    Freiheit und Frieden leben können, tragen wir gemeinsam
                                    Verantwortung. <br/><br/>
                                    Orte der Demokratie sind also nicht nur ein Anlass, zu fragen, was in der
                                    Vergangenheit war, sondern angesichts der gegenwärtigen Krisen in Europa und
                                    anderswo auf der Welt auch ein Auftrag für die Zukunft: Mögen die Orte der
                                    Demokratie uns Mahnung, Gebot und Verpflichtung sein, gemeinsam immer und
                                    überall
                                    für eine Zukunft in Demokratie, Freiheit und Frieden einzutreten – für ein Leben
                                    in
                                    Würde!“
                                    <span className="author">
                                    Ilse Aigner, MdL, Präsidentin des Bayerischen Landtags
                                </span>
                                </blockquote>
                            </div>
                        </div>
                    </Container.Row>
                </Container.Part>
                <Container.Fullwidth background="beige">
                    <Container.Part>
                        <Container.Row>
                            <div className="col-12">
                                <h2 id="sliderTitle">Die Orte der Demokratie</h2><span></span>
                            </div>
                        </Container.Row>
                    </Container.Part>
                    <OrteSlider/>
                </Container.Fullwidth>

                <div className="clearfix small-space"/>

                <Container.Part background="beige">
                    <Container.Row>
                        <div className="col-6">
                            <h3 className="blue">Wo?</h3>
                            <p>
                                Wo genau sich die „Orte der Demokratie" befinden, ist auf der Interaktiven Karte zu
                                entdecken.
                            </p>
                            <p><Link to="/interaktive-karte" className="icon-arrow_right icon-bg">Interaktive
                                                                                                  Karte</Link></p>
                        </div>
                        <div className="col-6 center">
                            <Link to="/interaktive-karte"><img alt="öffnet die interaktive Karte"
                                                               src={'images/karte_odd.png'}/></Link>
                        </div>
                    </Container.Row>
                </Container.Part>

                <Container.Part background="white" env="w">
                    <Container.Row>
                        <div className="col-12">
                            <h2>Aktuelle Termine</h2>
                            <div className="termine">
                                <div className="row termin">
                                    <div className="col-4">
                                        <span className="date">
                                            Do. 07.03. – Fr. 14.06.2024
                                        </span>
                                        <p>
                                            Bamberg
                                        </p>
                                    </div>
                                    <div className="col-8">
                                        <p>
                                            Stadtarchiv Bamberg<br/>
                                            Mo., Di. 8 – 16 Uhr<br/>
                                            Mi., Fr. 8 – 12.30 Uhr | Do. 8 – 18 Uhr<br/>
                                            Eintritt frei
                                        </p>
                                        {/*<p>*/}
                                        {/*    <Link*/}
                                        {/*        to="https://www.etermin.net/corona_schnelltest?servicegroupid=93966"*/}
                                        {/*        target="_blank" className="icon-arrow_right icon-bg">*/}
                                        {/*        Anmeldung*/}
                                        {/*    </Link>*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12">
                            <div className="termine">
                                <div className="row termin">
                                    <div className="col-4">
                                        <span className="date">
                                            Do. 27.06. – Fr. 05.07.2024
                                        </span>
                                        <p>
                                            Ermershausen
                                        </p>
                                    </div>
                                    <div className="col-8">
                                        <p>
                                            Rathaus Ermershausen<br/>
                                            Mo., Di. 8 – 16 Uhr<br/>
                                            Mi., Fr. 8 – 12.30 Uhr | Do. 8 – 18 Uhr<br/>
                                            Eintritt frei
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container.Row>
                </Container.Part>

                <Container.Part background="white" env="w">
                    <Container.Row>
                        <div className="col-6">
                            <h2>Material für Schulen</h2>
                            <p>
                                Die methodischen und thematischen Vorschläge, die gemeinsam mit der
                                <strong> Bayerische Landeszentrale für politische Bildungsarbeit</strong> entwickelt
                                wurden nehmen
                                Schülerinnen und Schüler mit auf eine Zeitreise, bei der sie die Orte der Demokratie
                                in
                                Bayern besuchen und durch die Auseinandersetzung mit der Demokratiegeschichte das
                                Bewusstsein für den Wert unserer heutigen Demokratie schärfen.
                            </p>
                            <p>
                                <Link
                                    to="https://www.blz.bayern.de/materialschuber-ortederdemokratieelementar_p_357.html"
                                    target="_blank" className="icon-arrow_right icon-bg">
                                    Materialschuber „Orte der Demokratie in Bayern“
                                </Link>
                                <br/><br/>
                                <Link to="https://www.blz.bayern.de/zeit-fuer-politik/der-bayerische-landtag.html"
                                      target="_blank" className="icon-arrow_right icon-bg">
                                    Film über den Bayerischen Landtag für den Einsatz im Unterricht
                                </Link>
                            </p>
                        </div>
                        <div className="col-6 center">
                            <img alt="" src={'images/book_placeholder.png'}/>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>

    )
}

export default IndexPage
