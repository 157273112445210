import React from "react";
import * as Container from "./container/container";
import {Link} from "gatsby";

const OrteSlider = () => {

    const sliderConfig = {
        'type': 'slider',
        'startAt': 0,
        'perView': 3,
        'rewind': false,
        'breakpoints': {
            '1279': {
                'perView': 2
            },
            '767': {
                'perView': 1,
            },
            '575': {
                'perView': 1
            }
        },
    };

    return (
        <Container.Slider id="0" classNames="slider-orte" sliderTitleId="sliderTitle" config={sliderConfig}>
            <li className="glide__slide teaser teaser-ort">
                <Link to="/orte/memmingen">
                    <h3 className="blue">1525 <br/> Memmingen<br/>Haus der Kramerzunft</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Memmingen" src={'/images/Memmingen/teaser-memmingen.jpg'}/>
                    </figure>
                </Link>

                <div className="teaser-body">
                    <p>In den Zwölf Artikeln forderten die Bauern
                       in Oberschwaben 1525 Menschen- und
                       Freiheitsrechte. </p>
                    <p>
                        Mit dem Aufkommen der Reformation
                        beriefen sie sich auf „göttliches Recht“.
                    </p>
                    <Link to="/orte/memmingen" className="icon-arrow_right icon-bg">1525 Memmingen</Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="/orte/regensburg">
                    <h3 className="blue">1594 | 1663-1806 <br/> Regensburg <br/> Altes Rathaus</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Regensburg" src={'/images/Regensburg/teaser-regensburg.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Der „Immerwährende Reichstag“
                       war die Vertretung der Reichsstände und
                       tagte als Gesandtenkongress von 1663 bis
                       1806 dauerhaft in Regensburg.
                    </p>
                    <p>
                        Die Reichsstände und der Kaiser verhandelten hier Fragen der Gesetzgebung.
                    </p>
                    <Link to="/orte/regensburg" className="icon-arrow_right icon-bg">1594 | 1663 Regensburg</Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="/orte/gaibach">
                    <h3 className="blue">1828 | 1832 <br/> Gaibach <br/> <br/></h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Gaibach" src={'/images/Gaibach/teaser-gaibach.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p> An der Konstitutionssäule in Gaibach wird seit 1828 regelmäßig die bayerische
                        Verfassung von 1818 gefeiert.</p>
                    <p> Beim Gaibacher Fest 1832 wurden Rufe nach einer Reform
                        der Verfassung, nach mehr Volksbeteiligung und einer Sicherung der
                        Meinungsfreiheit
                        laut. </p>
                    <Link to="/orte/gaibach" className="icon-arrow_right icon-bg">1828 | 1832
                                                                                  Gaibach </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/muenchen-prannerstrasse">
                    <h3 className="blue">1818 – 1934<br/>München<br/>Prannerstraße 8</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite der Prannerstraße 8 in München"
                             src={'/images/Muenchen-Prannestr/teaser-prannerstrasse.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Der Bayerische Landtag war das maßgebliche Forum für Bayerns Entwicklung von
                        einer
                        konstitutionellen Monarchie hin zu einer parlamentarischen Demokratie. Von 1818
                        bis
                        1933/34 tagte er in der Prannerstraße in München.</p>
                    <Link to="orte/muenchen-prannerstrasse" className="icon-arrow_right icon-bg">
                        1818 – 1934 München </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="/orte/bamberg">
                    <h3 className="blue">1919<br/>Bamberg<br/>Spiegelsaal </h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Bamberg" src={'/images/Bamberg/teaser-bamberg.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p> Der Spiegelsaal der Bamberger Harmonie ist ein zentraler Ort für die moderne
                        bayerische Verfassungsgeschichte. Hier wurde 1919 die erste demokratische
                        Verfassung
                        des Freistaats Bayern verabschiedet. </p>
                    <Link to="/orte/bamberg" className="icon-arrow_right icon-bg"> 1919 Bamberg </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/vilshofen">
                    <h3 className="blue">1919<br/>Vilshofen<br/>an der Donau</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Vilshofen" src={'/images/Vilshofen/teaser-vilshofen.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>In Vilshofen fand 1919 der erste Politische Aschermittwoch statt.</p>
                    <p>Die Tradition des rhetorischen Schlagabtausches zwischen den Parteien
                       entwickelte sich immer mehr zu einem Polit-Event von überregionalem
                       Interesse.</p>
                    <Link to="orte/vilshofen" className="icon-arrow_right icon-bg"> 1919
                                                                                    Vilshofen </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/nuernberg">
                    <h3 className="blue">1919<br/>Nürnberg<br/>Gewerkschaftshaus</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Nürnberg" src={'/images/Nuernberg/teaser-nuernberg.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Die Vertreter der freien Gewerkschaften aus ganz Deutschland trafen sich 1919 in
                        Nürnberg zur Gründung eines gemeinsamen Dachverbands – dem „Allgemeinen Deutschen
                        Gewerkschaftsbund“.</p>
                    <Link to="orte/nuernberg" className="icon-arrow_right icon-bg"> 1919
                        Nürnberg </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/wohlmuthshuell">
                    <h3 className="blue">1945<br/>Wohlmuthshüll<br/><br/></h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Wohlmuthshüll"
                             src={'/images/Wohlmuthshuell/teaser-wohlmuthshuell.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Die frühe Wahl der Gemeindevertreter in Wohlmuthshüll können als Beitrag zur Erneuerung von
                       Demokratie und Gesellschaft in Bayern interpretiert werden.</p>
                    <Link to="orte/wohlmuthshuell" className="icon-arrow_right icon-bg"> 1945
                                                                                         Wohlmuthshüll </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/muenchen-lmu">
                    <h3 className="blue">1946<br/>München<br/>Große Aula der LMU</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite der Ludwig-Maximilians-Universität"
                             src={'/images/Muenchen-LMU/teaser-lmu.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>In der Großen Aula der LMU beriet ab Juli 1946 die Verfassunggebende
                       Landesversammlung
                       über die bis heute gültige Verfassung des Freistaats Bayern.</p>
                    <Link to="orte/muenchen-lmu" className="icon-arrow_right icon-bg"> 1946
                                                                                       München </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/herrenchiemsee">
                    <h3 className="blue">1948<br/>Herrenchiemsee<br/><br/></h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Herrenchiemsee"
                             src={'/images/Herrenchiemsee/teaser-herrenchiemsee.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Auf Herrenchiemsee leistete im August 1948 ein Sachverständigengremium wichtige
                       Vorarbeiten für das Grundgesetz der Bundesrepublik Deutschland.</p>
                    <Link to="orte/herrenchiemsee" className="icon-arrow_right icon-bg"> 1948
                                                                                         Herrenchiemsee </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/passau">
                    <h3 className="blue">1952<br/>Passau<br/><br/></h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Passau" src={'/images/Passau/teaser-passau.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Mit den Festspielen Europäische Wochen in Passau wurde 1952 ein Anstoß für den
                       europäischen Einigungsprozess gegeben.</p>
                    <Link to="orte/passau" className="icon-arrow_right icon-bg"> 1952 Passau </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/ermershausen">
                    <h3 className="blue">1978<br/>Ermershausen<br/><br/></h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite von Ermershausen"
                             src={'/images/Ermershausen/teaser-ermershausen.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Der Ort Ermershausen, der über Jahre hinweg mit Ausdauer gegen die kommunale
                       Gebietsreform protestierte, ist ein Beispiel für die Möglichkeiten von
                       zivilgesellschaftlichem Engagement</p>
                    <Link to="orte/ermershausen" className="icon-arrow_right icon-bg"> 1978
                                                                                       Ermershausen </Link>
                </div>
            </li>
            <li className="glide__slide teaser teaser-ort">
                <Link to="orte/muenchen-maximilianeum">
                    <h3 className="blue">1949–heute<br/>München<br/>Maximilianeum</h3>
                    <figure className="teaser-img">
                        <img alt="öffnet die Seite vom Maximilianeum" src={'/images/Muenchen-Maximilianeum/teaser-maximilianeum.jpg'}/>
                    </figure>
                </Link>
                <div className="teaser-body">
                    <p>Das Maximilianeum ist als Sitz des bayerischen Parlaments seit 1949 der zentrale
                        Ort für die parlamentarische Demokratie in Bayern.</p>
                    <Link to="orte/muenchen-maximilianeum"
                          className="icon-arrow_right icon-bg"> 1949–heute
                        München </Link>
                </div>
            </li>
        </Container.Slider>
    )
}

export default OrteSlider;

